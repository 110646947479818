import {Modal, ModalBody, ModalFooter, ModalHeader, Spinner,} from "reactstrap";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import * as instanceAPI from "../../../../api/evolution/instance";
import {bindActionCreators} from "redux";
import * as ConexoesActions from "../../../../store/conexoes/actions";
import {connect} from "react-redux";
import {QRCode} from "react-qrcode-logo";
import logo from "../../../../assets/images/logo-sm-dark.png";
import {getStatusColor} from "../../../../api/evolution/status";
import {notificationError} from "../../../../util/notification";

const ConnectModal = (props) => {
    const [connectionMessage, setConnectionMessage] = useState(
        "Escaneie o QR Code...",
    );
    const [connectionStatus, setConnectionStatus] = useState(
        props.conexoes.conexaoConnect.connectionStatus,
    );
    const handleCloseModal = () => {
        props.getConexaoExit();
        props.getConexoes();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (connectionStatus !== "open") {
                    const value = await instanceAPI.getConnectionStateInstance(
                        props.conexoes.conexaoConnect.name,
                    );
                    if (value) {
                        setConnectionStatus(value.state);
                    }
                } else if (connectionStatus === "open") {
                    setConnectionMessage("Conexão bem-sucedida...");
                    handleCloseModal();
                }
            } catch (e) {
                notificationError(props.t);
                props.getConexaoFailed(e);
            }
        };
        const timeout = setInterval(() => {
            if (props.conexoes.conexaoConnect !== null) {
                fetchData();
            }
        }, 2000);
        return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (props.conexoes.conexaoConnect !== null) {
                setConnectionMessage("Atualizando Qr Code ...");
                props.getConexaoFetch(props.conexoes.conexaoConnect);
            } else {
                throw Error();
            }
        };
        const timeout = setInterval(() => {
            if (props.conexoes.conexaoConnect !== null) {
                fetchData()
                    .then(() => {
                        setConnectionMessage("Escaneie o QR Code...");
                    })
                    .catch((reason) => {
                        notificationError(props.t);
                        props.getConexaoFailed(reason);
                    });
            }
        }, 30000);
        return () => clearInterval(timeout);
    }, []);

    return (
        <Modal
            className="text-center"
            title={props.t("Connect")}
            centered
            isOpen={props.conexoes.isOpenModalConnect}
            toggle={handleCloseModal}
        >
            <ModalHeader className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Modal Connection Title")}
                </h5>
                <button
                    type="button"
                    onClick={handleCloseModal}
                    className="btn-close-modal"
                    data-dismiss="modal"
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <ModalBody>
                {props.conexoes.qrCodeConnect && !props.conexoes.loadingQrcode ? (
                    <QRCode
                        removeQrCodeBehindLogo
                        value={props.conexoes.qrCodeConnect.code}
                        logoImage={logo}
                        size={300}
                        fgColor="#385de8"
                        logoWidth={300 * 0.3}
                        logoPaddingStyle="square"
                        qrStyle="squares"
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    />
                ) : (
                    <Spinner type="grow" className="m-auto" color="primary"/>
                )}
                <p style={{textAlign: "center"}}>{connectionMessage}</p>
            </ModalBody>
            <ModalFooter>
                <p style={{textAlign: "center"}}>
                    Status: {getStatusColor(connectionStatus)}
                </p>
                <br/>
            </ModalFooter>
        </Modal>
    );
};

ConnectModal.propTypes = {
    isOpenModalConnect: PropTypes.bool,
    conexoes: PropTypes.object,
    t: PropTypes.any,
    getConexaoFetch: PropTypes.func,
    getConexaoExit: PropTypes.func,
    getConexaoFailed: PropTypes.func,
    getConexoes: PropTypes.func,
};

const mapStatetoProps = (state) => ({conexoes: state.conexoes});
const mapDispatchtoProps = (dispatch) =>
    bindActionCreators(ConexoesActions, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchtoProps,
)(withTranslation()(ConnectModal));
