// src/components/GruposTable.js

import React, {useState} from "react";
import {MDBDataTable, MDBInput} from "mdbreact";
import {withTranslation} from "react-i18next";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import logoZapEase from "../../../../../../../assets/images/logo-sm-dark.png";
import {any, array, func} from "prop-types";

const GruposTable = (props) => {
    const [isCheckAll, setIsCheckAll] = useState(false);
    const allRows = [];
    const {data, selectedRows, handleSelectRows} = props;

    const handleRowClick = (id) => {
        const newSelectedRows = [...selectedRows];
        const index = newSelectedRows.indexOf(id);
        if (index !== -1) {
            newSelectedRows.splice(index, 1);
        } else {
            newSelectedRows.push(id);
        }
        handleSelectRows(newSelectedRows);
    };

    const handleCheckAll = () => {
        if (isCheckAll) {
            handleSelectRows([]);
            setIsCheckAll(false);
        } else {
            handleSelectRows(allRows);
            setIsCheckAll(true);
        }
    };

    const columns = [
        {
            label: (
                <MDBInput
                    type="checkbox"
                    onClick={handleCheckAll}
                    checked={isCheckAll}
                    className="border-primary"
                />
            ),
            field: "checked",
            sort: "disabled",
            width: 5,
        },
        {
            label: <div style={{textAlign: "center"}}>Foto</div>,
            field: "photo",
            sort: "disabled",
            width: 100,
        },
        {
            label: <div style={{textAlign: "center"}}>Grupo</div>,
            field: "titulo",
            sort: "asc",
            searchable: true,
            width: 150,
        },
    ];
    const modifiedRows = data.map((row) => {
        allRows.push(row.id);
        return {
            titulo: row.subject,
            id: row.id,
            photo: (
                <div style={{textAlign: "center"}}>
                    <img
                        src={row.pictureUrl !== null ? row.pictureUrl : logoZapEase}
                        className="rounded-circle avatar-sm m-auto"
                        alt={row.id}
                    />
                </div>
            ),
            clickEvent: () => handleRowClick(row.id),
            checked: (
                <MDBInput
                    className="border-primary"
                    type="checkbox"
                    onClick={() => handleRowClick(row.id)}
                    checked={selectedRows.includes(row.id)}
                    contrast
                />
            ),
        };
    });

    return (
        <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                        <CardTitle>{props.t("Groups")} </CardTitle>
                        <MDBDataTable
                            striped
                            autoWidth
                            bordered
                            sortable
                            fixed
                            responsive
                            data={{columns, rows: modifiedRows}}
                            searchLabel={props.t("Search")}
                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                            entries={20}
                            entriesLabel={props.t("Entries Label")}
                            infoLabel={[
                                props.t("Showing"),
                                props.t("To"),
                                props.t("Of"),
                                props.t("Entries"),
                            ]}
                            noRecordsFoundLabel={props.t("No Records Found")}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
GruposTable.propTypes = {
    data: array,
    selectedRows: array,
    handleSelectRows: func,
    t: any,
};

export default withTranslation()(GruposTable);
