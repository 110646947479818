import {call, put, takeEvery} from "redux-saga/effects";
import * as instanceAPI from "../../api/evolution/instance";
import {genHash} from "../../util/hash";
import * as conexoes from "../../db/firestore/conexoes";
import {
    createConexaoFailed,
    createConexaoSuccessful,
    getConexaoSuccess,
    getConexoes,
    getConexoesFailed,
    getConexoesSuccessful,
    logoutConexaoFailed,
    logoutConexaosSuccessful,
} from "./actions";
import {
    CREATE_CONEXAO,
    GET_CONEXOES,
    GET_CONNECT_CONEXAO,
    GET_CONNECT_CONEXAO_FETCH,
    LOGOUT_CONEXAO_FETCH,
} from "./actionTypes";
import {getFirebaseBackend} from "../../helpers/firebase_helper";

async function evolutionCreateInstance(name) {
    return await instanceAPI.postCreateInstance(name);
}

async function evolutionLogoutInstance(name) {
    return await instanceAPI.deleteLogoutInstance(name);
}

async function evolutionFetchInstance(name) {
    return await instanceAPI.getFetchInstances(name);
}

async function evolutionConnectInstance(name) {
    return await instanceAPI.getConnectInstace(name);
}

async function firebaseUpdateConexoes(name, response) {
    return await conexoes.setConexao(name, response);
}

async function getConexao() {
    const uid = getFirebaseBackend().getAuthenticatedUser().uid;
    const doc = await getFirebaseBackend().getConexaoCollection().doc(uid).get();
    if (doc.exists) {
        return doc.data().instancias;
    } else {
        return [];
    }
}

function* createConexao() {
    try {
        const name = genHash();
        const response = yield call(evolutionCreateInstance, name);
        yield call(firebaseUpdateConexoes, name, response);
        yield put(createConexaoSuccessful(response));
        yield put(getConexoes());
    } catch (e) {
        console.error(e);
        yield put(createConexaoFailed(e));
    }
}

function* getConexoesSaga() {
    try {
        const instancias = [];
        const response = yield call(getConexao);
        for (const responseElement of response) {
            const inst = yield call(evolutionFetchInstance, responseElement.name ? responseElement.name : responseElement.instance.instanceName);
            instancias.push(inst[0]);
        }
        yield put(getConexoesSuccessful(instancias));
    } catch (e) {
        console.error(e);
        yield put(getConexoesFailed(e));
    }
}

function* logoutConexaoSaga(action) {
    try {
        yield call(evolutionLogoutInstance, action.payload.name);
        yield put(logoutConexaosSuccessful());
    } catch (e) {
        console.error(e);
        yield put(logoutConexaoFailed(e));
    }
}

function* connectSaga(action) {
    try {
        const response = yield call(
            evolutionConnectInstance,
            action.payload.data.instance.name,
        );
        yield put(getConexaoSuccess(response));
    } catch (e) {
        console.error(e);
        yield put(getConexoesFailed(e));
    }
}

function* conexaoSaga() {
    yield takeEvery(CREATE_CONEXAO, createConexao);
    yield takeEvery(GET_CONEXOES, getConexoesSaga);
    yield takeEvery(LOGOUT_CONEXAO_FETCH, logoutConexaoSaga);
    yield takeEvery(GET_CONNECT_CONEXAO, connectSaga);
    yield takeEvery(GET_CONNECT_CONEXAO_FETCH, connectSaga);
}

export default conexaoSaga;
